import ClipfyCard from "../cards/aiyu/ClipfyCard";

import aiyu from "../../images/aiyu.jpg";

function Links() {
  return (
    <div>
      <ClipfyCard name="アイユー（株" img={aiyu} description="愛知県" />
    </div>
  );
}
export default Links;
