import ClipfyCard from "../cards/nami/ClipfyCard";

import nami from "../../images/nami.jpg";

function Nami() {
  return (
    <div>
      <ClipfyCard name="アイユー（株" img={nami} description="愛知県" />
    </div>
  );
}
export default Nami;
